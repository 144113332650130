.header {
  opacity: 0;
  transition: all 0.3s;

  &.viewable {
    opacity: 0.9;

    &.naviOpened {
      opacity: 1;
    }
  }
}
